import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
import { HashLink } from '../components/link';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`The Future of Programming by Bob Martin`}</h1>
    <div style={{
      "display": "flex",
      "justifyContent": "center"
    }}>
  <iframe width="560" height="315" src="https://www.youtube.com/embed/ecIWPzGEbFc" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{
        "margin": "1rem auto"
      }}></iframe>
    </div>
    <p>{`In order to understand where programming is headed, it helps to understand where it’s been. In this talk, Uncle Bob Martin outlines some computing history before predicting what the next generation of programming looks like.`}</p>
    <h2><HashLink id="demographic" to="/future-of-programming#demographic" mdxType="HashLink">{`The Programmer Demographic`}</HashLink></h2>
    <p>{`“Back in the earliest days of software women were roughly half the programmers that were there. Not quite, but almost half.”`}</p>
    <p>{`So why is our field so male-dominant today? The demographics have shifted. In addition to gender, the average age of a programmer has dropped to less than 30 years old.`}</p>
    <p>{`The demographics have changed, hardware has improved but software is more-or-less the same. We can understand code written decades ago. Engineers from decades ago would be able to understand modern code.`}</p>
    <p>{`Bob speculates that the number of programmers doubles every 5 years. This would mean that in 1995, for example, there were tens of millions of programmers, half of whom have less than five years of experience. This “leaves our industry in a state of perpetual inexperience” since we’ll always have half of our engineering population with less than 5 years of experience.`}</p>
    <p>{`In addition to our exponential growth, it’s worth noting that we don’t have enough teachers to teach the new people coming in. This creates an environment where folks are making the same mistakes over and over again, unnecessarily.`}</p>
    <h2><HashLink id="agile" to="/future-of-programming#agile" mdxType="HashLink">{`The Agile Movement`}</HashLink></h2>
    <p>{`Agile methodology outlines disciplines, not process steps. These core technical disciplines are promises you make not tasks that you do. Agile was about discipline, craftsmanship and professionalism.`}</p>
    <p>{`Then “the business” got a hold of scrum.`}</p>
    <p>{`You cannot go to the business and ask, “Is it okay if we write tests?” or, “Is it okay if we refactor?”. What you’re trying to do by asking this question is shed the risk. The business cannot evaluate that so they will deny you and not take the risk.`}</p>
    <p>{`This risk is ours to take. We are the ones who know we need tests and who know that our code should be refactored. A professional takes the risk for things that need to be done.`}</p>
    <p>{`Agile was developed by technical people. Certification for project managers took Agile in a different direction. Project management took over the Agile movement. People started forgetting about the technical craftsmanship and started focusing on business practices.`}</p>
    <p>{`Kent Beck stated the goal for Agile was, “Healing of the divide between business and programming.” Bob suggests that the Agile movement failed to accomplish that goal.`}</p>
    <h2><HashLink id="future" to="/future-of-programming#future" mdxType="HashLink">{`The Future`}</HashLink></h2>
    <p>{`Software systems are all around us. You can’t do anything in the modern world without interacting with software. This puts a tremendous amount of responsibility on programmers.`}</p>
    <p>{`“We rule the world. The world doesn’t know this, yet. We don’t quite know it, yet. Other people believe that they rule the world but then they write the rules down and they hand them to us.”`}</p>
    <p>{`Bob predicts that there will be a catastrophe in the future where many people die as a result of bad software. When the time comes, folks will start asking questions. Software engineers won’t be able to blame a deadline or a boss. Politicians will regulate us.`}</p>
    <p>{`The way to avoid this is to regulate ourselves before this happens. We need to develop principles and ethics. We’ll need to create a system to enforce this self-regulation. The future of programming is one where we are a true professional body with a set of morals and disciplines that we all follow.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      